import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const sevenbybatCustomerGroup = 'iceland';
export const customerGroup = sevenbybatCustomerGroup;

const supportEmail = {
	iceland: 'sevenbybat@smoothr.de'
};
const firebaseConfig = {
	iceland: {
		apiKey: 'AIzaSyB1Y4wG6BoFE2hi8irI30ofKfscXoEPPDw',
		authDomain: 'prod-env-smoothr.firebaseapp.com',
		databaseURL: 'https://prod-env-smoothr.firebaseio.com',
		projectId: 'prod-env-smoothr',
		storageBucket: 'prod-env-smoothr.appspot.com',
		messagingSenderId: '640058019537',
		appId: '1:640058019537:web:5c0e94bd67f65652cbfff0',
		measurementId: 'G-G8B1VPFQG7'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyB1zuV-fVlLlytAWtsVdSapFLRkuETwcyg',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup,
	...apiEnvironment,
	countryList: ['de'],
	hasRecommendations: false,
	showDiffrenceModal: false,
	payPalValidatePrice: false,
	payPalMinPrice: 0
};
