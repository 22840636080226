import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatSnackBar} from '@angular/material/snack-bar';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {Api} from 'src/smoothr-web-app-core/api/api';
import BoardingPassResponse from 'src/smoothr-web-app-core/models/BoardingPassResponse';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Quagga from 'quagga';
import {BrowserMultiFormatReader, NotFoundException} from '@zxing/library';

enum SelectedInputType {
	SCAN = 'scan',
	MANUALLY = 'manually'
}
@Component({
	selector: 'app-scan-boarding-pass',
	templateUrl: './scan-boarding-pass.component.html',
	styleUrls: ['./scan-boarding-pass.component.scss']
})
export class ScanBoardingPassComponent implements OnInit {
	venue: Venue;
	selectedInputType: SelectedInputType = SelectedInputType.SCAN;
	selectedInputTypeEnum = SelectedInputType;
	boardingDataForm: FormGroup;
	barCode: string = '';
	loading = false;
	error = false;
	scanning = true;
	@ViewChild('videoElement', {static: false}) videoElement: ElementRef;
	private codeReader: BrowserMultiFormatReader;

	constructor(
		private fb: FormBuilder,
		private modalCtrl: ModalController,
		private snackbarCtrl: MatSnackBar,
		private translate: TranslateService
	) {
		this.boardingDataForm = this.fb.group({
			name: ['', Validators.required],
			surname: ['', Validators.required],
			date: ['', Validators.required],
			airCode: ['', [Validators.required]]
		});
		this.codeReader = new BrowserMultiFormatReader();
	}
	static async show(modalCtrl: ModalController, venue: Venue = null): Promise<BoardingPassResponse> {
		const modal = await modalCtrl.create({
			cssClass: 'boarding-pass auto-height',
			component: ScanBoardingPassComponent,
			componentProps: {
				venue
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		const result = (await modal.onDidDismiss()).data;

		return result;
	}
	ngOnInit() {
		this.boardingDataForm.valueChanges.subscribe(v => {});
	}
	ngAfterViewInit(): void {
		// Initialize Quagga for PDF417 scanning
		this.startScanning();
	}
	selectType(value: SelectedInputType) {
		this.selectedInputType = value;
	}

	onQrCodeResult(resultString: string) {
		console.log('QR Code Result: ', resultString);
	}
	startScanning() {
		this.codeReader.decodeFromVideoDevice(null, this.videoElement.nativeElement, (result: any, error) => {
			if (result) {
				// this.scanResult = result.text;
				console.log('Detected PDF417 barcode:', result);
				this.scanSuccessHandler(result.text);
			}
			if (error && !(error instanceof NotFoundException)) {
				console.error('Error during scan:', error);
			}
		});
		this.scanning = true;
	}

	stopScanning() {
		this.codeReader.reset();
		this.scanning = false;
	}

	scanSuccessHandler(event: string) {
		console.log(event);

		this.barCode = '';
		if (event) {
			console.log(event);
			const cutArray = event.split(' ');
			const emptySpaces = cutArray.filter(it => !it);
			const hasText = cutArray.filter(it => it);
			console.log(emptySpaces, hasText);
			if (hasText.length === 6) {
				this.barCode = event;
				this.snackbarCtrl.open('Scanned successful', null, {
					duration: 3000
				});
				console.log('success');
			}
			this.snackbarCtrl.open('Scanned successful', null, {
				duration: 3000
			});
		}
	}
	// isValid(controlName?: string): boolean {
	// 	if (controlName) {
	// 		return (
	// 			!this.boardingDataForm.get(controlName)?.value && this.boardingDataForm.get(controlName)?.touched
	// 		);
	// 	}
	// 	return this.boardingDataForm?.invalid;
	// }
	async manualClick() {
		if (!this.venue) {
			return;
		}
		this.loading = true;

		try {
			const formValue = this.boardingDataForm.value;
			console.log(formValue);

			const formData = {
				firstName: formValue.name,
				lastName: formValue.surname,
				airlineCode: formValue.airCode.trim().slice(0, 2),
				flightNumber: formValue.airCode.trim().slice(2, formValue.airCode.length).trim(),
				travelDate: formValue.date,
				venue: this.venue._id
			};
			const response = await Api.validateBoardingPassManually(formData);
			console.log(response);

			if (response['error']) {
				throw new Error('wrong data');
			}

			this.successfulDismiss(response);
		} catch (e) {
			this.error = true;
		} finally {
			this.loading = false;
		}
	}
	async qrCodeClick() {
		console.log('CLICK', this.venue, this.barCode);
		if (!this.venue) {
			return;
		}
		this.loading = true;

		try {
			this.scanning = false;
			const barCodeData = {
				barcodeData: this.barCode,
				venue: this.venue._id
			};
			const response = await Api.validateBoardingPassQR(barCodeData);
			console.log(response);
			if (response['error']) {
				throw new Error('wrong data');
			}
			this.successfulDismiss(response);
		} catch (e) {
			this.scanning = true;
			this.error = true;
		} finally {
			this.loading = false;
		}
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}

	successfulDismiss(result: BoardingPassResponse) {
		this.modalCtrl.dismiss(result);
	}

	ionViewWillLeave() {
		this.scanning = false;
	}

	onPdf417Detected(result: any) {
		console.log('PDF417 Result: ', result, result.codeResult.code);
		// Quagga.stop(); // Uncomment if you want to stop after the first scan
	}
}
