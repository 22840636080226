import {Component, AfterViewInit, ViewChild, ElementRef, OnDestroy} from '@angular/core';
import {BrowserMultiFormatReader, NotFoundException} from '@zxing/library';

@Component({
	selector: 'app-pdf417-scanner',
	templateUrl: './pdf417-scanner.component.html',
	styleUrls: ['./pdf417-scanner.component.scss']
})
export class Pdf417ScannerComponent implements AfterViewInit, OnDestroy {
	@ViewChild('videoElement', {static: false}) videoElement: ElementRef;
	private codeReader: BrowserMultiFormatReader;
	scanning: boolean = false;
	scanResult: string | null = null;

	constructor() {
		this.codeReader = new BrowserMultiFormatReader();
	}

	ngAfterViewInit(): void {
		this.startScanning();
	}

	ngOnDestroy(): void {
		this.stopScanning();
	}

	startScanning() {
		this.codeReader.decodeFromVideoDevice(null, this.videoElement.nativeElement, (result, error) => {
			if (result) {
				// this.scanResult = result.text;
				console.log('Detected PDF417 barcode:', result);
			}
			if (error && !(error instanceof NotFoundException)) {
				console.error('Error during scan:', error);
			}
		});
		this.scanning = true;
	}

	stopScanning() {
		this.codeReader.reset();
		this.scanning = false;
	}
}
